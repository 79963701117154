import React, {Component} from 'react';

class Page404 extends Component {
    render(){
        return(
            <div className="container center" style={{marginTop: "10%"}}>
                <h1>404</h1>
                <h4>Page Not Found</h4>
            </div>
        )
    }
}

export default Page404;